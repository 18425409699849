.user-input{
    margin-top: 10px;
}

.Users-root {
    max-width: 1200px;
    width: 80%;
    padding: 10px;
}

.App-logo{
    background-image: url(/images/digatex.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    height: 40px;
    margin-top: 8px;
}
.User-query {
    background-image: url(/images/bg02.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    width: 70%;
    height: 55px;
    margin-bottom: 10px;
}

.User-query input {
    margin-top: 15px;
}
.User-query button {
    margin-top: 15px;
    margin-left: 5px;
}
.commands{
    float: right;
    margin: 10px 0
}
.MuiMenu-list {
    outline: 0;
    max-height: 500px;
}
