#menu {
	position: fixed;
	top: 15px;
	right: 40px;
}
#search {
	position: fixed;
	top: 15px;
	left: 40px;
}
#menu button {
	font-size: 1.5em;
}

.g-recaptcha {
	display: inline-block;
}

#g-recaptcha.empty {
	border: 1px solid #a94442;
	border-radius: .25em;
}

.icon-home {
	color: black;
	font-size: 25px;
	vertical-align: -7px;
}

.menu-direction {
	display: table-caption;

}

@media only screen and (min-width: 1250px) {
	.menu-direction {
		display: inline;
	}
}
.menu-direction {
	display: table-caption;

}

@media only screen and (min-width: 1250px) {
	.menu-direction {
		display: inline;
	}
}
