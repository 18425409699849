.Admin-root {
	max-width: 70%;
	padding: 10px;
}

.Admin-root input[type="file"] {
	margin: 10px 0;
}

.Preview-panel ul {
	float: left;
	margin: 15px 0;
}

.Preview-panel button {
	float: left;
	margin: 15px;
}

.Versions-panel button {
	margin-left: 10px;
}
