.Users-root {
	max-width: 600px;
	padding: 10px;
}

.Report-date-from {
	padding-left: 0;
	padding-right: 5px;
}

.Report-date-to {
	padding-left: 5px;
	padding-right: 0;
}